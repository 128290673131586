import styled, {keyframes} from "styled-components";
import {InfoBoxContainer} from "../../dashboard-container/index.styles";
import Manrope from "../../manrope";
import SpaceGrotesk from "../../space-grotesk";

type HomePageContentProps = {
    isDrawerOpen?: boolean;
    isMobile?: boolean | null;
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1; 
  }
`;

const MainContainer = styled.div<{ isMobile?: boolean | null }>`
  animation: ${fadeIn} 0.7s ease-in-out;
  width: 80%;
  ${(props) =>
    props.isMobile &&
    `
    max-width: 100%;
  `}
`;

const HeaderContainer = styled.div`
  align-content: left;
  flex-direction: column;
  align-items: left;
  text-align: left;
  width: 100%;
`;

const BackButtonWrapper = styled.img`
  height: 30px;
  width: 30px;
  margin-right: 10px;
  margin-bottom: 5px;

  &:hover {
    cursor: pointer;
  }

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

const ChatBotContainer = styled(InfoBoxContainer)`
    width: auto;
`
const StyledSpaceGrotesk = styled(SpaceGrotesk)<HomePageContentProps>`
  color: var(--MainBlue, var(--Default-Stroke, #225C90));
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
  font-size: ${({ isMobile }) => (isMobile ? "36px" : "48px")};
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const StyledManrope = styled(Manrope)<HomePageContentProps>`
  color: var(--MainBlue, var(--Default-Stroke, #225C90));
  leading-trim: both;
  text-edge: cap;
  font-size: ${(props) => (props.isMobile ? "14px" : "20px")};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 3rem;
  margin-top: 0px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin-top: 1rem;
  gap: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
`;

const StyledInput = styled.input`
  flex: 1;
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid var(--LightGray, #ddd);
  border-radius: 5px;
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: var(--MainBlue, #225C90);
  }
`;

const SubmitButton = styled.button`
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: var(--MainBlue, #225C90);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: var(--MainBlueHover, #184d78);
  }

  &:disabled {
    background-color: var(--DisabledGray, #ccc);
    cursor: not-allowed;
  }
`;

const ResponseContainer = styled.div`
  margin-top: 2rem;
  padding: 1.5rem;
  border: 1px solid var(--LightGray, #ddd);
  border-radius: 5px;
  background-color: var(--LightBackground, #f9f9f9);

  p {
    margin: 0.5rem 0 0;
    font-size: 1rem;
    color: var(--DarkText, #333);
  }
`;

const ResetButton = styled.button`
  margin-top: 1rem;
  padding: 0.6rem 1rem;
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--MainBlue, #225C90);
  background-color: transparent;
  border: 1px solid var(--MainBlue, #225C90);
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: var(--MainBlue, #225C90);
    color: #fff;
  }
`;

const ExamplesContainer = styled.div`
  margin-top: 2rem;
  padding: 1rem;
  border-top: 1px solid var(--LightGray, #ddd);
`;

const ExampleItem = styled.p`
  font-size: 1rem;
  color: var(--DarkText, #555);
  margin: 0.5rem 0;
  font-style: italic;
`;

const FAQLinkContainer = styled.div`
  margin-top: 2rem;
  text-align: center;
`;

const FAQLink = styled.button`
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--MainBlue, #225C90);
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: var(--MainBlueHover, #184d78);
  }
`;


export {
    MainContainer,
    HeaderContainer,
    BackButtonWrapper,
    ChatBotContainer,
    StyledManrope,
    StyledSpaceGrotesk,
    InputContainer,
    StyledInput,
    SubmitButton,
    ResponseContainer,
    ResetButton,
    ExamplesContainer,
    ExampleItem,
    FAQLinkContainer,
    FAQLink
}