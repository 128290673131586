import Inter from "../inter";
import styled from "styled-components";
import InterSemiBold from "../inter-semi-bold";
import Manrope from "../manrope";

type ErrorProps = {
  isError?: boolean | null;
};

type PasswordProps = {
  type?: string;
  id: string;
  onClick: () => void;
};

type DropdownItemProps = {
  selected?: boolean;
};

const Label = styled.label`
  display: block;
`;

const StyledManropeLabel = styled(Manrope)`
  font-size: 16px;
  text-align: left;

  color: var(--Default-Stroke, #282828);
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const ErrorLabel = styled(Manrope)<ErrorProps>`
  display: ${(props) => (props.isError ? "flex" : "none")};
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  text-align: left;
  color: #cb3101;
`;

const ErrorText = styled.div`
  height: 100%;
  display: inline;
  margin: auto 10px;
`;

const EyeImage = styled.img<PasswordProps>`
  display: ${(props) => (props.type === "password" ? "inline" : "none")};
  flex: 0.1;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  align-self: center;
  &:hover {
    cursor: pointer;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  gap: 1.25rem;
`;

const DropdownHeader = styled.div<{
  isOpen: boolean;
  isError?: boolean;
  disabled?: boolean;
}>`
  padding: 10px;
  cursor: pointer;
  display: flex;
  height: 2.5rem;
  padding: 0.1rem 1.25rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 5px;
  color: var(--Black, var(--Default-Stroke, #282828));

  outline: ${(props) =>
    props.isError ? "1px solid #CB3101" : "1px solid var(--Black, #282828)"};
  background: var(--White, #ffff);
  ${(props) =>
    props.isOpen &&
    `
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  outline: 2px solid var(--Black, #282828);
  `}

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    background-color: lightgray;
    cursor: not-allowed;
    color: black;
  `}
`;

const DisplayText = styled(Manrope)<{ isPlaceholder?: boolean}>`
  leading-trim: both;

  text-edge: cap;
  /* Body 2 (14px) */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ isPlaceholder }) =>
      isPlaceholder &&
      `
      color: gray;
    `}
`;

const DropdownList = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 10;
  animation: ${({ isOpen }) => isOpen && "slideDown 0.4s ease forwards"};
  outline: 2px solid var(--Black, #282828);
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-20%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  max-height: 250px;
  overflow: scroll;
`;

const ArrowImage = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 1.25rem;
`;

const DropdownItem = styled.div<DropdownItemProps>`
  padding: 0.5rem 1.25rem;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
  ${(props) =>
    props.selected &&
    `
    background-color: #d1d1d1;
    &:hover {
      background-color: #d1d1d1;
    }
  `}
`;

const RequiredStarStyle = styled.span`
  color: #cb3101;
  margin-left: 0.25rem;
`;

export {
  ErrorLabel,
  ErrorText,
  EyeImage,
  Label,
  StyledManropeLabel,
  DropdownContainer,
  DropdownHeader,
  DisplayText,
  DropdownList,
  ArrowImage,
  DropdownItem,
  RequiredStarStyle,
};
