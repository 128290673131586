import styled, { keyframes } from "styled-components";
import InterSemiBold from "../../inter-semi-bold";
import SpaceGrotesk from "../../space-grotesk";
import Manrope from "../../manrope";
import {InfoBoxContainer} from "../../dashboard-container/index.styles";
import DashboardContainer from "../../dashboard-container";

type HomePageContentProps = {
  isDrawerOpen?: boolean;
  isMobile?: boolean | null;
};

const StyledHeader = styled(SpaceGrotesk)<HomePageContentProps>`
  color: var(--MainBlue, var(--Default-Stroke, #225C90));
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
  font-size: ${({ isMobile }) => (isMobile ? "36px" : "48px")};
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const StyledInterSemiBold = styled(InterSemiBold)<HomePageContentProps>`
  color: var(--MainBlue, var(--Default-Stroke, #225C90));
  leading-trim: both;
  text-edge: cap;
  text-align: left;
  font-family: InterSemiBold;
  font-size: ${(props) => (props.isMobile ? "16px" : "24px")};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
`;

const StyledManrope = styled(Manrope)<HomePageContentProps>`
  color: var(--MainBlue, var(--Default-Stroke, #225C90));
  leading-trim: both;
  text-edge: cap;
  font-size: ${(props) => (props.isMobile ? "14px" : "20px")};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 3rem;
  margin-top: 0px;
`;

const StyledImage = styled.img`
  height: 30px;
  width: 30px;
  margin-right: 10px;
  margin-bottom: 5px;

  &:hover {
    cursor: pointer;
  }

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1; 
  }
`;

const MainContainer = styled.div<{ isMobile?: boolean | null }>`
  animation: ${fadeIn} 0.7s ease-in-out;
  width: 100%;
  
  ${(props) =>
    props.isMobile &&
    `
    max-width: 100%;
  `}
`;

const FormContainer = styled(InfoBoxContainer)`
  width: auto;
  max-width: ${(props) => (props.mobile ? "100%" : "100vw")};
  padding: ${(props) => (props.mobile ? "0.5rem" : "3rem")};
`

const RSVPSuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2rem;
`;

const PageContainer = styled(DashboardContainer)<{ isMobile?: boolean | null }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => (props.isMobile ? "1rem" : "2rem")};
  margin: 0 auto;
  max-width: ${(props) => (props.isMobile ? "100%" : "60rem")};
  box-sizing: border-box;
`;

export {
  StyledHeader,
  StyledManrope,
  StyledInterSemiBold,
  StyledImage,
  PageContainer,
  MainContainer,
  FormContainer,
  RSVPSuccessContainer,
};
