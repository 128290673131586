import React, { useState, useEffect, useRef } from "react";
import {
  ChipContainer,
  Chip,
  DropdownContainer,
  DropdownHeader,
  DropdownList,
  DropdownItem,
  SelectedChips,
  ErrorLabel,
  ErrorText,
  Label,
  StyledManropeLabel,
  DisplayText,
  ArrowImage,
  RequiredStarStyle,
} from "./index.styles";
import Input from "../input-box";

type ChipComponentProps = {
  options: string[];
  selectedOptions: string[];
  onChange: (selected: string[]) => void;
  id: string;
  title: string;
  placeholder?: string;
  isError?: boolean;
  isTextError?: boolean;
  errorLabel?: string;
  requiredStar?: boolean;
  otherPlaceholder?: string;
  hideOther?: boolean;
  disabled?: boolean;
  dontAbsolute?: boolean;
};

const ChipComponent: React.FC<ChipComponentProps> = ({
  options,
  selectedOptions,
  onChange,
  id,
  title,
  placeholder = "-Select-",
  isError,
  isTextError,
  errorLabel,
  requiredStar,
  otherPlaceholder = "Enter your answer here",
  hideOther = false,
  disabled = false,
  dontAbsolute = false,
}) => {
  const [selectedChips, setSelectedChips] = useState<string[]>(selectedOptions);
  const [isOpen, setIsOpen] = useState(true);
  const [showOther, setShowOther] = useState(false);
  const [isNone, setIsNone] = useState(false);

  const [isErrorSelect, setIsErrorSelect] = useState<boolean | undefined>(true);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState<
    string | null
  >(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsErrorSelect(false);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(false);
    }, 1);
  }, []);

  // useEffect(() => {
  //   if (selectedOption.length === 0 && value) {
  //     setSelectedOption(value);
  //   }
  // }, [value]);

  const updateOtherOption = (option: string) => {
    let updatedOption = `Other - ${option}`;
    let updatedChips = [];

    updatedChips = selectedChips.filter((chip) => !chip.includes("Other"));
    updatedChips.push(updatedOption.trim());

    setSelectedChips(updatedChips);
    onChange(updatedChips);
  };

  const handleChipClick = (option: string) => {
    if (
      option === "None" ||
      selectedChips.includes("None") ||
      option === "Prefer Not to Answer" ||
      selectedChips.includes("Prefer Not to Answer")
    ) {
      setIsNone(true);
      setShowOther(false);
      setSelectedChips([option]);
      onChange([option]);
      return;
    }
    if (selectedChips.includes(option)) {
      // setSelectedChips([
      //   ...selectedChips.filter((chip) => chip !== option),
      //   option,
      // ]);
      return;
    }
    let updatedChips: string[] = [];

    // Toggle selection for other options
    updatedChips = selectedChips.includes(option)
      ? selectedChips.filter((chip) => chip !== option)
      : [...selectedChips, option];
    setSelectedChips(updatedChips);
    onChange(updatedChips);
  };

  const handleOptionClick = (option: string) => {
    if (option === "Other") {
      setShowOther(true);
      handleChipClick(option);
      setSelectedDropdownOption(null);
    } else {
      setSelectedDropdownOption(option);
    }
  };

  const handleChipRemove = (chip: string, event: React.MouseEvent) => {
    if (chip === "Other") {
      setShowOther(false);
    }
    if (!selectedChips.includes("Other")) {
      setShowOther(false);
    }

    event.stopPropagation();

    const updatedChips = selectedChips.filter((c) => c !== chip);
    setSelectedChips(updatedChips);
    onChange(updatedChips);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <ChipContainer>
      <>
        <DropdownContainer ref={dropdownRef}>
          {requiredStar ? (
            <Label htmlFor={id}>
              <StyledManropeLabel>
                {title}
                <RequiredStarStyle>*</RequiredStarStyle>
              </StyledManropeLabel>
            </Label>
          ) : (
            <Label htmlFor={id}>
              <StyledManropeLabel>{title}</StyledManropeLabel>
            </Label>
          )}
          <DropdownHeader
            onClick={disabled ? () => {} : toggleDropdown}
            isOpen={isOpen}
            isError={isErrorSelect || isError}
            disabled={disabled}
          >
            <DisplayText isPlaceholder={selectedChips.length < 1}>
              {selectedChips.length > 0 ? (
                <SelectedChips>
                  {selectedChips.map((chip) => (
                    <Chip
                      key={chip}
                      onClick={(event) => handleChipRemove(chip, event)}
                    >
                      {chip}
                      <span>&times;</span>
                    </Chip>
                  ))}
                </SelectedChips>
              ) : (
                placeholder
              )}
            </DisplayText>
            <ArrowImage
              src={
                isOpen
                  ? "assets/application/arrow-up.svg"
                  : "assets/application/arrow-down.svg"
              }
              alt={isOpen ? "up arrow" : "down arrow"}
            />
          </DropdownHeader>
          <DropdownList isOpen={isOpen} dontAbsolute={dontAbsolute}>
            {options
              .filter((option) => option !== "Other")
              .map((option, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => handleChipClick(option)}
                  selected={option === selectedDropdownOption}
                >
                  <DisplayText>{option}</DisplayText>
                </DropdownItem>
              ))}
            <DropdownItem
              key={options.length}
              onClick={() => handleOptionClick("Other")}
            >
              <DisplayText>{"Other"}</DisplayText>
            </DropdownItem>
          </DropdownList>
        </DropdownContainer>
        {!hideOther && (
          <div style={{ display: showOther ? "block" : "none" }}>
            <Input
              id={id + "-other"}
              type="text"
              errorLabel="Sorry, you have entered incorrect data."
              placeholder={otherPlaceholder}
              isError={isTextError}
              disabled={disabled}
              onChange={(e) => {
                updateOtherOption(e.target.value);
              }}
            />
          </div>
        )}
        <ErrorLabel isError={isError}>
          <img src="/assets/icons/error.svg" alt="error" />
          <ErrorText>{errorLabel}</ErrorText>
        </ErrorLabel>
      </>
    </ChipContainer>
  );
};

export default ChipComponent;
