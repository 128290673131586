import React, {useEffect, useState} from "react";
import Button from "../button";
import {useMobileDetect} from "../../hooks/useMobileDetect";
import DashboardContainer from "../dashboard-container";
import {
    StyledH2,
    LoginForm,
    ApplicationPage,
    Buttons,
    ErrorBox,
    ErrorText,
    MainContainer,
} from "./index.styles";
import {useDispatch, useSelector} from "react-redux";
import {
    setAppPage,
    RootState,
    setAppStarted,
    setAppCompleted,
    setAppPronouns,
    setAppFirstName,
    setAppCity,
    setAppAge,
    setAppEthnicity,
    setAppDiscord,
    setAppSchool,
    setAppMajor,
    setAppYear,
    setAppResume,
    setAppGithub,
    setAppLinkedIn,
    setAppOtherLinks,
    setAppHackathonAttended,
    setAppNumberHackathon,
    setAppParentEmail,
    setAppElevatorPitch,
    setAppUofTHacksExp,
    setAppLearningProcess,
    setAppCountry,
    setAppTCInPerson,
    setAppTCMLH,
    setAppTCSubscribe,
    setAppLastName,
    setAppOptional,
    setAppGender,
    setAppPreferredName,
    resetRSVP,
    resetData,
    setIsAuthenticatedRedux,
} from "../../redux/store";
import AppPage1 from "./app-page-1";
import AppPage2 from "./app-page-2";
import AppPage3 from "./app-page-3";
import AppPage4 from "./app-page-4";
import AppPage5 from "./app-page-5";
import {useNavigate} from "react-router-dom";
import DialogBox from "../dialog-box";
import Progress from "./progress";
import LoadingCircleComponent from "../loading-component";
import {ageObject, reversedAgeObject} from "./dropdown-options/age";
import {API_BASE_URL, API_TYPE, API_VERSION} from "../../api/apiConfig";
import {useToaster} from "../../providers/ToasterProvider";

const ApplicationComponent = React.memo(() => {
    const isMobile = useMobileDetect();
    const dispatch = useDispatch();
    const { setToaster } = useToaster()

    const submitModalHeader = "Application Submission Confirmation";
    const submitModalBody =
        "Are you sure you want to submit? Once you submit, you will not be able to edit your application.";

    const {
        app_email,
        app_firstName,
        app_lastName,
        app_preferredName,
        app_page,
        app_started,
        app_tc_inPerson,
        app_tc_mlh,
        app_gender,
        app_pronouns,
        app_country,
        app_city,
        app_age,
        app_ethnicity,
        app_parent_email,
        app_discord,
        app_school,
        app_major,
        app_year,
        app_hackathon_attended,
        app_number_hackathon,
        app_elevator_pitch,
        app_uofthacks_exp,
        app_learning_process,
        app_optional,
        app_uofthacks_suggestions,
        app_github,
        app_linkedIn,
        app_otherLinks,
        app_completed,
        app_resume,
        app_resume_submitted,
        app_tc_subscribe,
    } = useSelector((state: RootState) => state.application);
    const {authToken, userID} = useSelector((state: RootState) => state.data);
    const [isGenderError, setGenderError] = useState(false);
    const [isGenderTextError, setGenderTextError] = useState(false);
    const [isPreferredNameError, setPreferredNameError] = useState(false)
    const [isEthnicityError, setEthnicityError] = useState(false)
    const [isEthnicityTextError, setEthnicityTextError] = useState(false)
    const [isPronounsError, setPronounsError] = useState(false);
    const [isPronounsTextError, setPronounsTextError] = useState(false);
    const [isCountryTextError, setCountryTextError] = useState(false);
    const [isCountryError, setCountryError] = useState(false);
    const [isCityError, setCityError] = useState(false);
    const [isAgeError, setAgeError] = useState(false);
    const [isAgeTextError, setAgeTextError] = useState(false);
    const [isDiscordError, setDiscordError] = useState(false);
    const [isSchoolError, setSchoolError] = useState(false);
    const [isSchoolTextError, setSchoolTextError] = useState(false);
    const [isMajorError, setMajorError] = useState(false);
    const [isMajorTextError, setMajorTextError] = useState(false)
    const [isGithubError, setGithubError] = useState(false);
    const [isLinkedInError, setLinkedInError] = useState(false);
    const [isYearError, setYearError] = useState(false);
    const [isYearTextError, setYearTextError] = useState(false);
    const [isHackathonAttendedError, setHackathonAttendedError] = useState(false);
    const [isElevatorPitchError, setElevatorPitchError] = useState(false);
    const [isUofTHacksExpError, setUofTHacksExpError] = useState(false);
    const [isLearningProcessError, setLearningProcessError] = useState(false);
    const [isOptionalError, setOptionalError] = useState(false)
    const [isTCError, setTCError] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);

    const [errorModal, setErrorModal] = useState(false);
    const [errorModalBody, setErrorModalBody] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setAppPage(1))
    }, []);

    // Set app has started upon opening application
    if (!app_started) {
        dispatch(setAppStarted(true));
    }

    const handleExpiredTokens = () => {
        dispatch(setIsAuthenticatedRedux(false));
        dispatch(resetData());
        dispatch(resetRSVP());
        setToaster("Your session has expired. Redirecting to login.", "error");
        setInitialLoad(false);
        navigate("/");
        return
    }

    const checkValid = (
        variable: string | boolean | number,
        nullValue: string | boolean | number,
        error: any
    ) => {
        error(false)
        if (
            variable === nullValue ||
            variable === undefined ||
            (typeof variable === "string" && variable.trim() === "")
        ) {
            error(true);
            return false;
        }
        error(false);
        return true;
    };

    const checkInputs = () => {
        let valid = true;
        if (app_completed) return true
        if (app_page === 1) {
            valid = checkValid(app_preferredName, "", setPreferredNameError) && valid;
            valid = checkValid(app_school, "", setSchoolError) && valid;
            if (app_school.includes("Other - ")) {
                valid =
                    checkValid(app_school.slice(8), "", setSchoolTextError) && valid;
            }
            if (app_gender.includes("Other - ")) {
                valid =
                    checkValid(app_gender.slice(8), "", setGenderTextError) && valid;
            }
            valid = checkValid(app_gender, "", setGenderError) && valid;
            if (app_gender.includes("Other - ")) {
                valid =
                    checkValid(app_gender.slice(8), "", setGenderTextError) && valid;
            }
            valid = checkValid(app_pronouns, "", setPronounsError) && valid;
            if (app_pronouns.includes("Other - ")) {
                valid =
                    checkValid(app_pronouns.slice(8), "", setPronounsTextError) && valid;
            }
            valid = checkValid(app_country, "", setCountryError) && valid;
            if (app_country.includes("Other - ")) {
                valid =
                    checkValid(app_country.slice(8), "", setCountryTextError) && valid;
            }
            valid = checkValid(app_city, "", setCityError) && valid;
            valid = checkValid(app_major, "", setMajorError) && valid;
            if (app_major.includes("Other - ")) {
                valid =
                    checkValid(app_major.slice(8), "", setMajorTextError) && valid;
            }
            valid = checkValid(app_year, "", setYearError) && valid;
            if (app_year.includes("Other - ")) {
                valid =
                    checkValid(app_year.slice(8), "", setYearTextError) && valid;
            }
            valid = checkValid(app_pronouns, "", setPronounsError) && valid;
            if (app_pronouns.includes("Other - ")) {
                valid =
                    checkValid(app_pronouns.slice(8), "", setPronounsTextError) && valid;
            }
            valid = checkValid(app_age, "", setAgeError) && valid;
            if (app_age.includes("Other - ")) {
                valid =
                    checkValid(app_age.slice(8), "", setAgeTextError) && valid;
            }
            valid = checkValid(app_ethnicity, "", setEthnicityError) && valid;
            if (app_ethnicity.includes("Other - ")) {
                valid =
                    checkValid(app_ethnicity.slice(8), "", setEthnicityTextError) && valid;
            }


            return valid;
        } else if (app_page === 2) {
            valid = checkValid(app_year, "", setYearError) && valid;
            if (app_year.includes("Other - ")) {
                valid = checkValid(app_year.slice(9), "", setYearTextError) && valid;
            }
            valid = checkValid(app_school, "", setSchoolError) && valid;
            valid = checkValid(app_major, "", setMajorError) && valid;
            if (app_github !== "") {
                // gh regex
                const ghRe =
                    /^(https?:\/\/)?(www\.)?github\.com\/[a-zA-Z0-9](?:[a-zA-Z0-9-])*(?:\/[a-zA-Z0-9](?:[a-zA-Z0-9-])*?)*$/;
                if (!ghRe.test(app_github)) {
                    setGithubError(true);
                    valid = false;
                } else {
                    setGithubError(false);
                }
            } else {
                setGithubError(false);
            }
            if (app_linkedIn !== "") {
                // li regex
                // can you make https optional?
                const liRe =
                    /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|company)\/[a-zA-Z0-9~-]+\/?$/;

                if (!liRe.test(app_linkedIn)) {
                    setLinkedInError(true);
                    valid = false;
                } else {
                    setLinkedInError(false);
                }
            } else {
                setLinkedInError(false);
            }
            valid = checkValid(app_discord, "", setDiscordError) && valid;
            return valid;
        }
        else if (app_page === 3) {
            if (app_hackathon_attended) {
                setHackathonAttendedError(app_number_hackathon <= 0);
                valid = app_number_hackathon > 0;
            }

            valid =
                checkValid(app_elevator_pitch, "", setElevatorPitchError) && valid;
            valid = checkValid(app_uofthacks_exp, "", setUofTHacksExpError) && valid;
            valid =
                checkValid(app_learning_process, "", setLearningProcessError) && valid;
            // valid = checkValid(app_optional, "", setOptionalError) && valid;

            return valid;
        } else if (app_page === 4) {
            valid = app_tc_inPerson && app_tc_mlh && valid;
            return valid;
        }
        return true;
    };

    const handleApplication = (pageChange: number) => {
        window.scrollTo({top: 0, behavior: "smooth"});

        if (app_page + pageChange < 1) {
            return;
        }
        if (app_page + pageChange === 5) {
            if (!app_completed) {
                setShowModal(true);
            } else {
                navigate("/");
            }
            return;
        }

        if (app_page + pageChange > 5) {
            navigate("/");
            return;
        }

        if (pageChange === -1 || checkInputs() === true || app_completed) {
            dispatch(setAppPage(app_page + pageChange));
        }
    };

    useEffect(() => {
        //starting
        // if app_completed is true, then we should fetch the data from the backend

        if (app_completed) {
            setInitialLoad(true);

            fetch(`${API_BASE_URL}/${API_VERSION}/application/get`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
            })
                .then((response) => {
                    if (response.status === 401) {
                        handleExpiredTokens()
                        return;
                    }

                    if (response.ok) {
                        response.json().then((data) => {
                            const {
                                first_name,
                                last_name,
                                preferred_name,
                                pronoun,
                                gender,
                                city,
                                age,
                                parents_email,
                                discord_username,
                                school,
                                major,
                                elevation_pitch,
                                mlh_marketing,
                                question_lens,
                                question_fictional,
                                question_optional,
                                country_of_residence,
                                level_of_study,
                                github_url,
                                linkedin_url,
                                other_links,
                                num_hackathons,
                                ethnicity,
                            } = data;

                            // remove this check
                            dispatch(setAppFirstName(first_name))
                            dispatch(setAppLastName(last_name))
                            dispatch(setAppPreferredName(preferred_name))
                            dispatch(setAppPronouns(pronoun || ""));
                            dispatch(setAppCountry(country_of_residence || ""));
                            dispatch(setAppCity(city));
                            dispatch(setAppGender(gender || ""))
                            dispatch(setAppAge(reversedAgeObject[age]));
                            dispatch(setAppParentEmail(parents_email));
                            dispatch(setAppDiscord(discord_username));
                            dispatch(setAppSchool(school));
                            dispatch(setAppMajor(major));
                            dispatch(setAppYear(level_of_study || ""));
                            dispatch(setAppGithub(github_url));
                            dispatch(setAppLinkedIn(linkedin_url));
                            dispatch(setAppOtherLinks(other_links));
                            dispatch(setAppHackathonAttended(num_hackathons > 0));
                            dispatch(setAppNumberHackathon(num_hackathons))
                            dispatch(setAppElevatorPitch(elevation_pitch));
                            dispatch(setAppUofTHacksExp(question_lens));
                            dispatch(setAppLearningProcess(question_fictional));
                            dispatch(setAppOptional(question_optional));
                            dispatch(setAppCompleted(true));
                            dispatch(setAppTCInPerson(true));
                            dispatch(setAppTCMLH(true));
                            dispatch(setAppTCSubscribe(mlh_marketing));
                            dispatch(setAppEthnicity(ethnicity))
                            setInitialLoad(false);

                            // we get resume now
                            fetch(
                                `${API_BASE_URL}/${API_VERSION}/${API_TYPE.APP}/resume/get`,
                                {
                                    method: "GET",
                                    headers: {
                                        Authorization: `Bearer ${authToken}`,
                                    },
                                }
                            )
                                .then((response) => response.blob())
                                .then((blob) => {
                                    if (blob.type != "application/pdf") {
                                        dispatch(setAppResume(null));
                                        return;
                                    }
                                    const file = new File([blob], "resume", {type: blob.type});
                                    dispatch(setAppResume(file));

                                })
                                .catch((error) => {
                                    dispatch(setAppResume(null));
                                })
                        });
                    } else {
                        response.json().then((data) => {
                            setErrorModalBody(data.message);
                            setErrorModal(true);
                            setInitialLoad(false);
                        });
                    }
                })
                .catch((error) => {
                    setErrorModalBody(
                        "Something went wrong at our end. Please try again later. If this issue persists, please contact us at"
                    );
                    setErrorModal(true);
                    setInitialLoad(false);
                });
        }
    }, [app_completed]);

    return (
        <MainContainer>
            <DashboardContainer goHome>
                <DialogBox
                    isOpen={showModal}
                    title={submitModalHeader}
                    body={submitModalBody}
                    extraBodyContent={
                        app_resume_submitted &&
                        app_resume?.size == undefined && (
                            <span>
                <br/>
                <b>
                  Note: It appears that your resume has not been uploaded
                  correctly. Please ensure that your resume has been uploaded
                  correctly before submitting. If the problem persists, please
                  contact us at{" "}
                    <a href="contact@uofthacks.com">contact@uofthacks.com</a>
                </b>
              </span>
                        )
                    }
                    button1Text={"Cancel"}
                    button2Text={"Submit"}
                    onClose={() => {
                        setShowModal(false);
                    }}
                    button1Click={() => {
                        setShowModal(false);
                    }}
                    button2Click={() => {
                        // this is where we should make the api call to backend and send all info
                        setInitialLoad(true);
                        fetch(`${API_BASE_URL}/${API_VERSION}/${API_TYPE.APP}/create`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${authToken}`,
                            },
                            body: JSON.stringify({
                                hacker_id: userID,
                                application_score: 0,
                                is_accepted: false,
                                first_name: app_firstName || '',
                                last_name: app_lastName || '',
                                preferred_name: app_preferredName || '',
                                phone_number: "are we even using this field?",
                                gender: app_gender || '',
                                email: app_email || '',
                                pronoun: app_pronouns || '',
                                country_of_residence: app_country || '',
                                city: app_city || '',
                                age: Number((ageObject as any)[app_age]) || 0,
                                school: app_school || '',
                                major: app_major || '',
                                level_of_study: `'${app_year}'`,
                                discord_username: app_discord || '',
                                github_url: app_github || "",
                                linkedin_url: app_linkedIn || "",
                                other_links: app_otherLinks || "",
                                is_first_hack: app_hackathon_attended || true,
                                elevation_pitch: app_elevator_pitch || '',
                                question_lens: app_uofthacks_exp || '',
                                question_fictional: app_learning_process || '',
                                question_optional: app_optional || '',
                                num_hackathons: app_number_hackathon || 0,
                                parents_email: app_parent_email || '',
                                resume: app_resume || '',
                                mlh_coc: true,
                                mlh_ctc_pp: true,
                                mlh_marketing: app_tc_subscribe || false,
                                ethnicity: app_ethnicity || '',
                            }),
                        })
                            .then((response) => {
                                if (response.status === 401) {
                                    handleExpiredTokens()
                                    return;
                                }

                                if (response.ok) {
                                    // go to app submitted page
                                    setShowModal(false);
                                    dispatch(setAppPage(app_page + 1));
                                    dispatch(setAppCompleted(true));
                                    setInitialLoad(false);

                                    // now send in resume

                                    const formData = new FormData();
                                    if (app_resume) {
                                        formData.append("resume", app_resume);
                                    }
                                    fetch(
                                        `${API_BASE_URL}/${API_VERSION}/${API_TYPE.APP}/resume/submit`,
                                        {
                                            method: "POST",
                                            headers: {
                                                Authorization: `Bearer ${authToken}`,
                                            },
                                            body: formData,
                                        }
                                    )
                                        .then(response => response.json())
                                        .then(data => {
                                            if (response.status === 401) {
                                                handleExpiredTokens()
                                                return;
                                            }

                                            if (response.ok) {
                                                console.log("Resume uploaded successfully:", data);
                                            } else {
                                                console.error("Error uploading resume:", data);
                                            }
                                        })
                                        .catch(error => {
                                            console.error("Fetch error:", error);
                                        });;
                                } else {
                                    setShowModal(false);
                                    setErrorModal(true);
                                    response.json().then((data) => {
                                        setErrorModalBody(data.message);
                                    });
                                    setInitialLoad(false);
                                }
                            })
                            .catch((error) => {
                                setShowModal(false);
                                setErrorModal(true);
                                setErrorModalBody(
                                    "Something went wrong at our end. Please try again later. If this issue persists, please contact us at contact@uofthacks.com"
                                );
                                setInitialLoad(false);
                            });
                    }}
                />
                <DialogBox
                    isOpen={errorModal}
                    title={"Something doesn't seem right..."}
                    body={errorModalBody}
                    button1Text={"OK"}
                    onClose={() => {
                        setErrorModal(false);
                    }}
                    button1Click={() => {
                        setErrorModal(false);
                    }}
                />
                <LoadingCircleComponent isOpen={initialLoad}/>

                <StyledH2 isMobile={isMobile}>
                    {document.getElementById("page-" + String(app_page + 1))
                        ? "Application Form"
                        : "Application Submitted"}
                </StyledH2>
                <LoginForm
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                    }}
                >
                    <AppPage1
                        isCityError={isCityError}
                        isGenderError={isGenderError}
                        isPronounsError={isPronounsError}
                        isCountryError={isCountryError}
                        isGenderTextError={isGenderTextError}
                        isPronounsTextError={isPronounsTextError}
                        isCountryTextError={isCountryTextError}
                        isAgeError={isAgeError}
                        isMajorError={isMajorError}
                        isSchoolError={isSchoolError}
                        isSchoolTextError={isSchoolTextError}
                        isMajorTextError={isMajorTextError}
                        isStudyYearError={isYearError}
                        isStudyYearTextError={isYearTextError}
                        isEthnicityError={isEthnicityError}
                        isEthnicityTextError={isEthnicityTextError}
                        isAgeTextError={isAgeTextError}
                        isPreferredNameError={isPreferredNameError}
                    />

                    <AppPage2
                        isYearError={isYearError}
                        isYearTextError={isYearTextError}
                        isSchoolError={isSchoolError}
                        isMajorError={isMajorError}
                        isGithubError={isGithubError}
                        isDiscordError={isDiscordError}
                        isLinkedInError={isLinkedInError}
                    />
                    <AppPage3
                        isElevatorPitchError={isElevatorPitchError}
                        isHackathonAttendedError={isHackathonAttendedError}
                        isLearningProcessError={isLearningProcessError}
                        isOptionalError={isOptionalError}
                        isUofTHacksExpError={isUofTHacksExpError}
                    />
                    <AppPage4 isTCError={isTCError}/>
                    <AppPage5/>

                    <Buttons
                        page={app_page}
                        last={
                            document.getElementById("page-" + String(app_page + 1))
                                ? false
                                : true
                        }
                    >
                        <Button
                            text="Back"
                            colour="#225C90"
                            onClick={() => handleApplication(-1)}
                            isMobile={isMobile}
                            style={{
                                display:
                                    app_page > 1 &&
                                    document.getElementById("page-" + String(app_page + 1))
                                        ? "block"
                                        : "none",
                            }}
                        />

                        <Button
                            text={
                                app_page === 4 && !app_completed
                                    ? "Submit"
                                    : app_page === 4 && app_completed
                                        ? "Return to Dashboard"
                                        : app_page === 5
                                            ? "Return to Dashboard"
                                            : (document.getElementById("page-" + String(app_page + 1)) && !app_completed)
                                                ? "Save and Continue"
                                                : "Continue"
                            }
                            isMobile={isMobile}
                            colour="blue"
                            filled
                            onClick={() => handleApplication(+1)}
                            disabled={
                                app_page === 4 ? !(app_tc_inPerson && app_tc_mlh) : false
                            }
                        />
                    </Buttons>

                    <Progress
                        in_app={
                            document.getElementById("page-" + String(app_page + 1)) != null
                        }
                        value={
                            app_completed
                                ? 1
                                : app_page === 0
                                    ? 0
                                    : app_page < 4
                                        ? (app_page - 1) / 3
                                        : app_page === 4
                                            ? 0.95
                                            : 1
                        }
                    />
                </LoginForm>
            </DashboardContainer>
        </MainContainer>
    );
});

export default ApplicationComponent;
