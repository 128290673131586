export const ageOptions = [
  "12 and under",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50 and older"
];

export const ageObject = {
  "12 and under": 12,
  "13": 13,
  "14": 14,
  "15": 15,
  "16": 16,
  "17": 17,
  "18": 18,
  "19": 19,
  "20": 20,
  "21": 21,
  "22": 22,
  "23": 23,
  "24": 24,
  "25": 25,
  "26": 26,
  "27": 27,
  "28": 28,
  "29": 29,
  "30": 29,
  "31": 31,
  "32": 32,
  "33": 33,
  "34": 34,
  "35": 35,
  "36": 36,
  "37": 37,
  "38": 38,
  "39": 39,
  "40": 40,
  "41": 41,
  "42": 42,
  "43": 43,
  "44": 44,
  "45": 45,
  "46": 46,
  "47": 47,
  "48": 48,
  "49": 49,
  "50 and older": 50,
};

export const reversedAgeObject = Object.keys(ageObject).reduce(
  (obj: { [key: number]: string }, key) => {
    obj[ageObject[key as keyof typeof ageObject]] = key;
    return obj;
  },
  {}
);
