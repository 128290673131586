import React, { useEffect, useState } from "react";
import {
    StyledSpaceGrotesk,
    StyledManrope,
  ButtonContainer,
  NoneButton,
  DashboardHeaderContainer,
  StyledLink,
} from "./index.styles";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import { HomeButton } from "./home-button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, setAppPage, setCharacterName } from "../../../redux/store";
import { useDispatch } from "react-redux";
import {PERSONALITY_QUIZ_RELEASE} from "../../../utils/featureFlags";

type HomeProps = {
  isDrawerOpen?: boolean;
};

const HomePageContent: React.FC<HomeProps> = React.memo(({ isDrawerOpen }) => {
  const [catImg, setCatImg] = useState("none");

  const { app_started, app_completed, character_name, redux_character_name } =
    useSelector((state: RootState) => state.application);

  const { userFName } = useSelector((state: RootState) => state.data);

  // get first name from user name if exists and store in name
  let name = userFName
    ? userFName.charAt(0).toUpperCase() + userFName.slice(1).toLowerCase()
    : "";

  const dashboardHeader = "Welcome to UofTHacks 12!"
  const beforeApplyingText = (
      <>
          Applications are due November 29, 2024 @ 11:59 PM ET
      </>
    
  )
  const deadlinePassedText = (
    <>Oops! It looks like the application deadline for UofTHacks 12 has passed. Don’t worry—there’s always next year, and we’d love to see you then!</>
  )
  // const beforeApplyingText = (
  //     <>
  //       Welcome to UofTHacks 12! Applications are now open, and we’d love to see you join us! <br />
  //       Apply now to be part of an exciting journey of innovation and creativity. <br />
  //       In the meantime, follow us on Instagram (
  //       <StyledLink href="https://www.instagram.com/uofthacks" target="_blank">
  //         @uofthacks
  //       </StyledLink>
  //       ) to stay up to date with our latest announcements and event highlights!
  //     </>
  // );
  const afterApplyingText = (
    <>
      Thank you for applying to UofTHacks 12! We will be reviewing your
      application and will get back to you soon :) <br /> Meanwhile, you can
      follow us on Instagram (
      <StyledLink href="https://www.instagram.com/uofthacks" target="_blank">
        @uofthacks
      </StyledLink>
      ) to stay up to date with our latest announcements!
    </>
  );

  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showEasterEgg = () => {
    if (catImg === "none") {
      setCatImg("block");
    } else {
      setCatImg("none");
    }
  };

  useEffect(() => {
    const keyDownHandler = (event: {
      key: string;
      preventDefault: () => void;
    }) => {
      if (event.key === "Escape") {
        showEasterEgg();
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [catImg]);
  return (
      <div style={{minHeight: '100vh'}}>
        <ButtonContainer style={{marginTop: 0}} mobile={isMobile}>
          <DashboardHeaderContainer mobile={isMobile}>
            <StyledSpaceGrotesk mobile={isMobile}>
              {dashboardHeader}
            </StyledSpaceGrotesk>
            <StyledManrope>
              {app_completed ? (
                  afterApplyingText
              ) : (
                  // <>Applications are now closed. Thank you for your interest!</>
                  deadlinePassedText
              )}
            </StyledManrope>
            {/*{catImg === "none" && (*/}
            {/*  <Image src="/assets/stars/star.svg" alt="" mobile={isMobile} />*/}
            {/*)}*/}
            {/*<BouncingImage*/}
            {/*  src="/assets/characters/cat.svg"*/}
            {/*  alt=""*/}
            {/*  display={catImg}*/}
            {/*  mobile={isMobile}*/}
            {/*/>*/}
          </DashboardHeaderContainer>
        </ButtonContainer>
        <ButtonContainer mobile={isMobile}>
          {/*<HomeButton*/}
          {/*  color="#FFD28E"*/}
          {/*  title="My Passport"*/}
          {/*  image="/assets/home-page/profile.svg"*/}
          {/*  onClick={() => {*/}
          {/*    navigate("/profile");*/}
          {/*  }}*/}
          {/*/>*/}
          <HomeButton
              // disabled={app_completed}
              disabled={true}
              color="#BAE8FC"
              title={
                app_completed
                    // ? "View Application"
                    ? "View Application"
                    : !app_started ?
                        "Start Application"
                        : "Continue Your Application"
              }
              image={
                app_completed
                    // ? "/assets/home-page/app.svg"
                    // : "/assets/home-page/locked.svg"
                    ? "/assets/home-page/app.svg"
                    : "/assets/home-page/app.svg"
              }
              onClick={() => {
                // if (app_completed) {
                //   dispatch(setAppPage(1));
                // }
                window.scrollTo({top: 0, behavior: "smooth"});
                // if (!app_completed) {
                //   navigate("/application");
                // }
                  navigate("/application");
              }}
          />
          <HomeButton
              color="#B6FFF6"
              title="Which Hacker Are You?"
              image={
                app_completed
                    ? "/assets/home-page/play.svg"
                    : "/assets/home-page/locked.svg"
              }
              disabled={!app_completed || !PERSONALITY_QUIZ_RELEASE}
              onClick={() => {
                if (character_name === "" || character_name === undefined) {
                  dispatch(setCharacterName(redux_character_name));
                }
                if (app_completed) {
                  navigate("/quiz");
                }
              }}
          />
        </ButtonContainer>
        <ButtonContainer mobile={isMobile}>
          <HomeButton
              color="#FFCDF7"
              title="FAQ / Contact Us"
              image="/assets/home-page/faq.svg"
              onClick={() => navigate("/faq")}
          />
          <NoneButton/>
        </ButtonContainer>
        {/* WILL BE IN SAME BUTTON CONTAINER AS HACKER PACK */}
        {/* <ButtonContainer mobile={isMobile}>
        <HomeButton
          color="#C0F9EB"
          title="Hacker Schedule"
          image="/assets/home-page/schedule.svg"
          onClick={() => navigate("/schedule")}
        />
        <NoneButton />
      </ButtonContainer> */}
        {/* <ButtonContainer mobile={isMobile}>
        <HomeButton
          color="#FFD28E"
          title="View Profile"
          image="/assets/home-page/profile.svg"
        />
        <HomeButton
          color="red"
          title="Hacker Schedule"
          disabled
          image="/assets/home-page/locked.svg"
        />
      </ButtonContainer>
      */}
      </div>
  );
});

export {HomePageContent};
