import React, { useState } from "react";
import { StyledDashboard } from "../../dashboard-container/index.styles";
import {
    MainContainer,
    HeaderContainer,
    BackButtonWrapper,
    ChatBotContainer,
    StyledManrope,
    StyledSpaceGrotesk,
    InputContainer,
    StyledInput,
    SubmitButton,
    ResponseContainer,
    ResetButton,
    ExamplesContainer,
    ExampleItem,
    FAQLink
} from "./index.styles";
import { useNavigate } from "react-router-dom";
import {API_BASE_URL, API_TYPE, API_VERSION} from "../../../api/apiConfig";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";

const ChatBot = () => {
    const navigate = useNavigate();
    const [question, setQuestion] = useState("");
    const [response, setResponse] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const {authToken} = useSelector(
        (state: RootState) => state.data
    );

    const handleQuestionSubmit = async () => {
        if (!question.trim()) return;
        setIsLoading(true);
        setResponse("");
        try {
            const res = await fetch(`${API_BASE_URL}/${API_VERSION}/${API_TYPE.USER}/chat`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({ prompt: question }),
            });
            const data = await res.json();
            setResponse(data.answer);
        } catch (error) {
            console.error("Error fetching chatbot response:", error);
            setResponse("Something went wrong. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const resetChatbot = () => {
        setQuestion("");
        setResponse("");
    };

    return (
        <StyledDashboard>
            <MainContainer>
                <HeaderContainer>
                    <BackButtonWrapper
                        src="/assets/home-page/back-button.svg"
                        onClick={() => navigate("/")}
                    />
                    <StyledSpaceGrotesk>Chat Bot</StyledSpaceGrotesk>
                    <StyledManrope>Your friendly chat-bot to help answer all questions about UoftHacks12!</StyledManrope>
                </HeaderContainer>
                <ChatBotContainer>
                    <InputContainer>
                        <StyledInput
                            type="text"
                            placeholder="Ask your question here..."
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            disabled={isLoading}
                        />
                        <SubmitButton
                            onClick={handleQuestionSubmit}
                            disabled={isLoading || !question.trim()}
                        >
                            {isLoading ? "Loading..." : "Submit"}
                        </SubmitButton>
                    </InputContainer>
                    {response && (
                        <ResponseContainer>
                            <StyledManrope>Response:</StyledManrope>
                            <p>{response}</p>
                            <ResetButton onClick={resetChatbot}>Ask Another Question</ResetButton>
                        </ResponseContainer>
                    )}
                    <ExamplesContainer>
                        <StyledManrope>Try asking:</StyledManrope>
                        <ExampleItem>What is the schedule for UofTHacks12?</ExampleItem>
                        <ExampleItem>How do I join the Discord server?</ExampleItem>
                        <ExampleItem>Who can I contact for technical help?</ExampleItem>
                    </ExamplesContainer>
                    <ExamplesContainer>
                        <StyledManrope>Looking for more help?</StyledManrope>
                        <FAQLink onClick={() => navigate("/faq")}>
                            Visit our FAQ page
                        </FAQLink>
                    </ExamplesContainer>
                </ChatBotContainer>
            </MainContainer>
        </StyledDashboard>
    );
};

export { ChatBot };
