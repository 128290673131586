import React, { useEffect, useState } from "react";
import Results from "./results/results";
import Button from "../button";
import { binTexts } from "./characters";
import {
  QuizContainer,
  HeaderContainer,
  HeaderTitle,
  QuestionText,
  NavigationContainer,
  QuestionCounter,
  PageContainer,
  HeaderSubtitle,
  FadeInComponent,
} from "./index.styles";
import questions from "./questions";
import OptionsComponent from "./option-component";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import {
  RootState,
  setCharacterName,
  setQuizQ1,
  setQuizQ2,
  setQuizQ3,
  setQuizQ4,
  setQuizQ5,
  setQuizQ6,
  setReduxCharacterName,
} from "../../redux/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import QuizLoadingComponent from "../loading-component/quiz-loading";
import { API_BASE_URL, API_TYPE, API_VERSION } from "../../api/apiConfig";

const QuizComponent = React.memo(() => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({
    M: 0,
    G: 0,
    A: 0,
    F: 0,
    H: 0,
    O: 0,
  });

  const {
    quiz_q1,
    quiz_q2,
    quiz_q3,
    quiz_q4,
    quiz_q5,
    quiz_q6,
    character_name,
  } = useSelector((state: RootState) => state.application);
  const { authToken } = useSelector((state: RootState) => state.data);

  const header_to_letter: Map<string, string> = new Map<string, any>([
    ["Moose", "M"],
    ["Goat", "G"],
    ["Axolotl", "A"],
    ["Falcon", "F"],
    ["Hedgehog", "H"],
    ["Owl", "O"],
  ]);

  const [selectedOption, setSelectedOption] = useState(quiz_q1);
  const [showResults, setShowResults] = useState(
    header_to_letter.get(character_name) != undefined
  );
  const [showOptions, setShowOptions] = useState(true);
  const [showQuizLoading, setShowQuizLoading] = useState(false);
  const [showResultLoading, setShowResultLoading] = useState(false);
  const [fadeInOpp, setFadeInOpp] = useState(false);
  const isMobile = useMobileDetect();

  useEffect(() => {
    setSelectedOption(quiz_q1);
  }, []);

  const dispatch = useDispatch();

  const handleOptionClick = (index: any) => {
    setSelectedOption(index);
  };

  // Moving quiz results logic to main quiz component
  // Having logic in results component made the logic run whenever the page re-renders
  // This would cause the API call to run more than once, and the character may change in the case of a tie
  const handleResultClick = (binCounts: any) => {
    setShowResultLoading(true);
    const bins = ["M", "G", "A", "F", "H", "O"];

    const maxCount = Math.max(...Object.values(binCounts).map(Number));
    const winningBins = bins.filter((bin) => binCounts[bin] === maxCount);
    // choose random bin from winning bins (in case of ties)
    const winningBin =
      winningBins[Math.floor(Math.random() * winningBins.length)];

    const { charName } = binTexts[winningBin as keyof typeof binTexts];

    // setTimeout(() => {
    //   setShowResultLoading(false);
    //   setShowResults(true);
    //   dispatch(setCharacterName(charName));
    //   dispatch(setReduxCharacterName(charName));
    // }, 2000);

    //send data to backend
    fetch(`${API_BASE_URL}/${API_VERSION}/${API_TYPE.USER}/personality`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        hacker_personality: charName,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setShowResultLoading(false);
        setShowResults(true);
        dispatch(setCharacterName(charName));
        dispatch(setReduxCharacterName(charName));
      })
      .catch((err) => {
        setShowResultLoading(false);
        setShowResults(true);
        dispatch(setCharacterName(charName));
        dispatch(setReduxCharacterName(charName));
      });
    // setShowResultLoading(false);
    // setShowResults(true);
  };

  const getBinForOption = (questionIndex: any, optionIndex: any) => {
    const optionToBinMappings = [
      ["M", "G", "F", "A"],
      ["H", "O", "M", "G"],
      ["F", "A", "O", "H"],
      ["M", "G", "F", "A"],
      ["O", "H", "M", "G"],
      ["F", "A", "O", "H"],
    ];

    return optionToBinMappings[questionIndex][optionIndex];
  };

  const chooseNextSelectedOption = () => {
    setFadeInOpp(false);
    setShowQuizLoading(true);
    setTimeout(() => {
      setShowQuizLoading(false);
    }, 1000);
    if (currentQuestion === 0) {
      setSelectedOption(quiz_q2);
    } else if (currentQuestion === 1) {
      setSelectedOption(quiz_q3);
    } else if (currentQuestion === 2) {
      setSelectedOption(quiz_q4);
    } else if (currentQuestion === 3) {
      setSelectedOption(quiz_q5);
    } else if (currentQuestion === 4) {
      setSelectedOption(quiz_q6);
    }
  };

  const choosePreviousSelectedOption = () => {
    setFadeInOpp(true);
    if (currentQuestion === 1) {
      setSelectedOption(quiz_q1);
    } else if (currentQuestion === 2) {
      setSelectedOption(quiz_q2);
    } else if (currentQuestion === 3) {
      setSelectedOption(quiz_q3);
    } else if (currentQuestion === 4) {
      setSelectedOption(quiz_q4);
    } else if (currentQuestion === 5) {
      setSelectedOption(quiz_q5);
    }
  };

  const handleNext = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (selectedOption !== -1) {
      const bin = getBinForOption(currentQuestion, selectedOption);
      setAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        [bin]: prevAnswers[bin] + 1,
      }));

      chooseNextSelectedOption();

      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        setShowQuizLoading(true);
        setTimeout(() => {
          handleResultClick(answers);
        }, 1000);
      }

      setShowOptions(false);
      setTimeout(() => {
        setShowOptions(true);
      }, 500);
    }
  };

  const handlePrevious = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (currentQuestion > 0) {
      choosePreviousSelectedOption();
      setCurrentQuestion(currentQuestion - 1);
      setShowOptions(false);
      setTimeout(() => {
        setShowOptions(true);
      }, 500);
    }
  };

  const handleRestart = () => {
    setAnswers({ M: 0, G: 0, A: 0, F: 0, H: 0, O: 0 });
    setSelectedOption(-1);
    setCurrentQuestion(0);
    setShowResults(false);
    dispatch(setQuizQ1(-1));
    dispatch(setQuizQ2(-1));
    dispatch(setQuizQ3(-1));
    dispatch(setQuizQ4(-1));
    dispatch(setQuizQ5(-1));
    dispatch(setQuizQ6(-1));
    dispatch(setCharacterName(""));
  };

  if (showResults) {
    const letter = header_to_letter.get(character_name);
    const { header, paragraph, imageSrc } =
      binTexts[letter as keyof typeof binTexts];
    return (
      <Results
        onRestart={handleRestart}
        header={header}
        paragraph={paragraph}
        imageSrc={imageSrc}
      />
    );
  }

  return (
    <PageContainer isQuiz goHome isMobile={isMobile}>
      <QuizLoadingComponent isOpen={showQuizLoading || showResultLoading} />
      <QuizContainer isMobile={isMobile}>
        <HeaderContainer>
          <HeaderTitle isMobile={isMobile}>
            What type of hacker are you?
          </HeaderTitle>

          {/*<HeaderSubtitle>*/}
          {/*  This does not affect your application in any way, it's just for fun!*/}
          {/*</HeaderSubtitle>*/}
        </HeaderContainer>
        {questions.map((question, index) => {
          if (index !== currentQuestion) {
            return null;
          }
          return (
              <FadeInComponent shouldFadeInOpp={fadeInOpp}>
                <QuestionText isMobile={isMobile}>{questions[currentQuestion].question}</QuestionText>
                <OptionsComponent
                  handleOptionClick={handleOptionClick}
                  options={question.options}
                  background={question.background}
                  selectedBackground={question.selectedBackground}
                  border={question.border}
                  currentQuestion={currentQuestion}
                  selectedIndex={selectedOption}
                  showOptions={showOptions}
              />
            </FadeInComponent>
          );
        })}
      </QuizContainer>
      <NavigationContainer isMobile={isMobile}>
        {((isMobile && currentQuestion !== 0) || !isMobile) && <Button
          text={isMobile ? "Previous" : "Previous Question"}
          colour="blue"
          filled={false}
          textColour="#0B3962"
          onClick={handlePrevious}
          style={{
            minWidth: isMobile ? "7rem" : "10rem",
            marginRight: isMobile ? "0" : "auto",
            visibility: currentQuestion === 0 ? "hidden" : "visible",
          }}
        />}
        {!isMobile &&
            <>
              <QuestionCounter isMobile={isMobile}>
                Question {currentQuestion + 1} of {questions.length}
              </QuestionCounter>
              <Button
                text={
                  currentQuestion === questions.length - 1
                    ? "Finish"
                    : isMobile
                    ? "Next" // Show "Next" on mobile
                    : "Next Question" // Show "Next Question" on desktop
                }
                colour="#0B3962"
                onClick={handleNext}
                disabled={
                  selectedOption === -1 ||
                  selectedOption === undefined ||
                  selectedOption === null
                }
                style={{
                  minWidth: isMobile ? "8rem" : "10rem",
                  marginLeft: isMobile ? "0" : "auto",
                  // marginRight: "1rem",
                }}
              />
            </>
        }
        {isMobile &&
            <>
              <Button
                  text={
                    currentQuestion === questions.length - 1
                        ? "Finish"
                        : isMobile
                            ? "Next" // Show "Next" on mobile
                            : "Next Question" // Show "Next Question" on desktop
                  }
                  colour="#0B3962"
                  onClick={handleNext}
                  disabled={
                      selectedOption === -1 ||
                      selectedOption === undefined ||
                      selectedOption === null
                  }
                  style={{
                    minWidth: isMobile ? "8rem" : "10rem",
                    marginLeft: isMobile ? "0" : "auto",
                    // marginRight: "1rem",
                  }}
              />
              <QuestionCounter isMobile={isMobile}>
                Question {currentQuestion + 1} of {questions.length}
              </QuestionCounter>
            </>
        }
      </NavigationContainer>
    </PageContainer>
  );
});

export default QuizComponent;
