import React from "react";
import {
    DrawerWrapper,
    DrawerContent,
    StyledSpaceGrotesk,
    DrawerButtonContainer,
    LogOutButton,
    CloseButton,
    Divider,
} from "./index.styles";
import {useMobileDetect} from "../../../hooks/useMobileDetect";
import {DrawerButton} from "./drawer-button";
import {useNavigate} from "react-router-dom";
import {
    RootState,
    resetApplication,
    resetData,
    resetRSVP,

    setIsAuthenticatedRedux,
} from "../../../redux/store";
import {useDispatch} from "react-redux";
import DialogBox from "../../../components/dialog-box";
import {useSelector} from "react-redux";
import {PERSONALITY_QUIZ_RELEASE} from "../../../utils/featureFlags";
import {ContentType} from "../../dashboard";

type DrawerProps = {
    isOpen: boolean;
    onClose: () => void;
    contentType: ContentType;
};

const Drawer: React.FC<DrawerProps> = ({isOpen, onClose, contentType}) => {
    const isMobile = useMobileDetect();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [logOutModalOpen, setLogOutModalOpen] = React.useState(false);
    const [pendingRoute, setPendingRoute] = React.useState<string | null>(null);


    const {isAccepted, didRSVP} = useSelector((state: RootState) => state.data);

    const {app_completed, character_name, redux_character_name} = useSelector(
        (state: RootState) => state.application
    );

    const drawerNavigate = (newRoute: string) => {
        navigate(newRoute);
        window.scrollTo({top: 0, behavior: "smooth"});
        isMobile && onClose();
        return

    }
    const handleNavigate = (newRoute: string) => {
        if (contentType && !newRoute.includes(contentType)) {
            if (contentType === 'quiz') {
                setPendingRoute(newRoute);
                return
            }
        }
        drawerNavigate(newRoute);
    };

    return (
        <DrawerWrapper isOpen={isOpen} mobile={isMobile}>
            <DrawerContent>
                <CloseButton onClick={onClose}>☰</CloseButton>
                <img src="/assets/icons/logo.svg" alt="Logo"
                     style={{marginTop: "30px", marginLeft: "2px", marginRight: "auto"}}/>
                <StyledSpaceGrotesk>Dashboard</StyledSpaceGrotesk>
                <Divider style={{backgroundColor: "white", height: "1px", margin: "2px 0"}}/>
                <DrawerButtonContainer>
                    <DrawerButton
                        title={"Home"}
                        image={"/assets/icons/home.svg"}
                        onClick={() => {
                            handleNavigate('/')
                        }}
                    />
                    <DrawerButton
                        title={"Personality Quiz"}
                        image={PERSONALITY_QUIZ_RELEASE ? "/assets/icons/crown.svg" : "/assets/icons/crown-disabled.svg"}
                        onClick={() => {
                            handleNavigate('/quiz')
                        }}
                        disabled={!PERSONALITY_QUIZ_RELEASE}
                    />
                    {/* <DrawerButton
                        title={"Application"}
                        image={"/assets/icons/page-edit.svg"}
                        onClick={() => {
                            handleNavigate('/application')
                        }}
                    /> */}
                    {isAccepted && (
                        <DrawerButton
                            title={"My Passport"}
                            image={"/assets/drawer/profile-1.svg"}
                            disabled={!didRSVP}
                            onClick={() => {
                                handleNavigate('/profile')
                            }}
                        />
                    )}
                    <DrawerButton
                        title={"FAQ"}
                        image={"/assets/icons/faq.svg"}
                        onClick={() => {
                            handleNavigate('/faq')
                        }}
                    />
                    {isAccepted && (
                        <>
                            <DrawerButton
                                title={"Hacker Schedule"}
                                image={"/assets/drawer/schedule-1.svg"}
                                disabled={!didRSVP}
                                onClick={() => {
                                    handleNavigate('/schedule')
                                }}
                            />
                            <DrawerButton
                                title={"Hacker Pack"}
                                image={"/assets/drawer/hacker-pack-1.svg"}
                                disabled={!didRSVP}
                                onClick={() => {
                                    window.open("/hacker-pack.pdf", "_blank");
                                    window.scrollTo({top: 0, behavior: "smooth"});
                                    isMobile && onClose();
                                }}
                            />
                            <DrawerButton
                                title={"First Hack Guide"}
                                image={"/assets/drawer/cog-1.svg"}
                                disabled={!didRSVP}
                                onClick={() => {
                                    window.open("/hackathon-guide.pdf", "_blank");
                                    window.scrollTo({top: 0, behavior: "smooth"});
                                    isMobile && onClose();
                                }}
                            />
                            <DrawerButton
                                title={"Hacker Bingo"}
                                image={"/assets/drawer/bingo-1.svg"}
                                disabled={!didRSVP}
                                onClick={() => {
                                    window.open("/hacker-bingo.pdf", "_blank");
                                    window.scrollTo({top: 0, behavior: "smooth"});
                                    isMobile && onClose();
                                }}
                            />
                        </>
                    )}
                </DrawerButtonContainer>
                <LogOutButton>
                    <DrawerButton
                        title={"Sign Out"}
                        image={"/assets/drawer/logout.svg"}
                        onClick={() => {
                            setLogOutModalOpen(true);
                        }}
                        dontHover
                    />
                </LogOutButton>
            </DrawerContent>
            <DialogBox
                isOpen={Boolean(pendingRoute)}
                title={"Potential Unsaved Changes"}
                body={
                    "Are you sure you want to leave? Some progress might get lost."
                }
                button1Text={"Leave"}
                button2Text={"Continue"}
                button1Click={() => {
                    if (pendingRoute) {
                        setPendingRoute(null);
                        drawerNavigate(pendingRoute);
                    }
                }}
                button2Click={() => {
                    setPendingRoute(null);
                }}
                onClose={() => {
                    setPendingRoute(null);
                }}
            />
            <DialogBox
                isOpen={logOutModalOpen}
                title={"Log Out Confirmation"}
                body={
                    "Are you sure you want to log out of your account? Logging out will end your current session."
                }
                button1Text={"Cancel"}
                button2Text={"Log Out"}
                button1Click={() => {
                    setLogOutModalOpen(false);
                }}
                button2Click={() => {
                    setLogOutModalOpen(false);
                    dispatch(setIsAuthenticatedRedux(false));
                    dispatch(resetApplication());
                    dispatch(resetData());
                    dispatch(resetRSVP());
                    navigate("/");
                }}
                onClose={() => {
                    setLogOutModalOpen(false);
                }}
            />
        </DrawerWrapper>
    );
};

export default Drawer;
