import styled from "styled-components";
import Subjectivity from "../subjectivity";
import SpaceGrotesk from "../space-grotesk";

type StyledDashboardProps = {
  mobile?: boolean | null;
  isQuiz?: boolean;
  goHome?: boolean;
};

const StyledDashboard = styled.div<StyledDashboardProps>`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto; 
  margin: ${(props) => (props.mobile ? "0 4vw 0 4vw" : "")};
  min-width: ${(props) => (props.mobile ? "" : "100%")};
  padding-bottom: 50px;
  align-content: center;
  position: relative;
`;

const ModuleBackground = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  z-index: -2;
  position: absolute;
`;

const HandIcon = styled.img`
    top: 130px; 
    right: 70px;
    z-index: -1;
    position: absolute;
`;

const AcornIcon = styled.img`
    bottom: 400px; 
    left: 100px;
    z-index: -1;
    position: absolute;
`;

const InfoBoxContainer = styled.div<StyledDashboardProps>`
  width: 100%;
  background: radial-gradient(69.75% 40.92% at 11.7% 11.66%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 205, 247, 0.20) 50%), linear-gradient(198deg, rgba(186, 232, 252, 0.60) 33.38%, rgba(254, 254, 255, 0.60) 84.04%)
  border-radius: 20px;
  padding: ${(props) => (props.mobile ? "0.5rem" : "1rem")};
  max-width: ${(props) => (props.mobile ? "100%" : "65vw")};
  position: relative;

  border-radius: 20px;
  border: 1.5px solid var(--Brand-Primary-200, #0DAEF1);
  background: radial-gradient(69.75% 40.92% at 11.7% 11.66%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 205, 247, 0.20) 50%), linear-gradient(198deg, rgba(186, 232, 252, 0.60) 33.38%, rgba(254, 254, 255, 0.60) 84.04%)
`;

const ColourText = styled.span<StyledDashboardProps>`
  ${(props) => (props.goHome ? "cursor: pointer;" : "")}
  color: #225C90
`;

const Image = styled.div<StyledDashboardProps>`
  display: ${(props) => (props.mobile ? "none" : "block")};
  position: absolute;
  top: -25px;
  right: -25px;
  object-fit: contain;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

const StyledSubjectivity = styled(SpaceGrotesk)<StyledDashboardProps>`
  margin: ${(props) => (props.mobile ? "2rem 0" : "1.25rem 0")};
  letter-spacing: 0px;
  text-align: left;
  padding: 0.62rem;
`;

export {
  StyledDashboard,
  ModuleBackground,
  InfoBoxContainer,
  ColourText,
  Image,
  StyledSubjectivity,
  HandIcon,
  AcornIcon,
};
