import React from "react";
import { ContentWrapper, StyledButton } from "./index.styles";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import { HomePageContent } from "../home";
import { FAQ } from "../faq";
import { Schedule } from "../schedule";
import { ChatBot } from "../chat-bot";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { AcceptedHomePageContent } from "../home/accepted-home";
import { Profile } from "../profile";
import { RSVP } from "../rsvp";
import ApplicationComponent from "../../application-form/index";
import Quiz from "../../../pages/quiz";

type ContentProps = {
    isDrawerOpen: boolean;
    onToggleDrawer: () => void;
    showFAQ?: boolean;
    showSchedule?: boolean;
    showProfile?: boolean;
    showRSVP?: boolean;
    showApplication?: boolean;
    showQuiz?: boolean;
    showChatBot?: boolean;
};

const Content: React.FC<ContentProps> = React.memo(
    ({
         isDrawerOpen,
         onToggleDrawer,
         showFAQ,
         showProfile,
         showRSVP,
         showSchedule,
         showApplication,
         showQuiz,
         showChatBot,
     }) => {
        const isMobile = useMobileDetect();
        const { isAccepted } = useSelector((state: RootState) => state.data);

        let content;
        let renderBottomGrid = false;
        if (showFAQ) {
            content = <FAQ />;
        } else if (showProfile) {
            content = <Profile isDrawerOpen={isDrawerOpen} />;
        } else if (showSchedule) {
            content = <Schedule />;
        } else if (showRSVP) {
            content = <RSVP />;
        } else if (showApplication) {
            content = <ApplicationComponent/>;
        } else if (showQuiz) {
            content = <Quiz/>
        } else if (showChatBot) {
            content = <ChatBot/>
        } else {
            renderBottomGrid = true
            content = isAccepted ? <AcceptedHomePageContent /> : <HomePageContent/>;
        }

        return (
            <>
                <StyledButton onClick={onToggleDrawer} isDrawerOpen={isDrawerOpen}>
                    ☰
                </StyledButton>
                <ContentWrapper
                    isDrawerOpen={isDrawerOpen}
                    mobile={isMobile}
                    profile={showProfile}
                >
                    {content}
                </ContentWrapper>

                {renderBottomGrid && <img
                    src="/backgrounds/grid.svg"
                    alt="Background Grid"
                    style={{position: 'fixed', bottom: 0, right: 0, width: (isDrawerOpen ? '82%' : '100%'), zIndex: -1}}
                />}
            </>
        );
    }
);

export default Content;
